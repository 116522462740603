<template>
  <div id="subForm" class="form sectionPadding">
    <div class="content-wrap">
      <div class="textBox" v-if="step != 4 &&  step != 3">
        <div class="upperTitleText white-1">
          {{ $t("SubscribeForm.upperText") }}
        </div>
        <h2 v-html="$t('SubscribeForm.title')" class="white"></h2>
        <div class="paragraph white-1">
          {{ $t("SubscribeForm.text") }}
        </div>
      </div>
      <div class="textBox textBox-1" v-if="step === 3">
        <h2 v-html="$t('SubscribeForm.title-1')" class="white"></h2>
        <div class="paragraph white-1">
          {{ $t("SubscribeForm.text-1") }}
        </div>
      </div>
      <div>
        <b-container v-if="step === 1" key="step-1">
          <div class="ContactForm ConctactSection ContactBG">
            <div class="contactInfo">
              {{ $t("SubscribeForm.step1") }}
            </div>
            <b-form @submit="onSubmit">
              <b-form-group>
                <label class="label primary-dark-color">{{
                  $t("SubscribeForm.name")
                }}</label>
                <b-form-input
                  v-model="form.name"
                  class="ContactInput inputText"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <label class="label primary-dark-color">{{
                  $t("SubscribeForm.phone")
                }}</label>
                <b-form-input
                  type="tel"
                  v-model="form.phone"
                  class="ContactInput inputText"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <label class="label primary-dark-color">{{
                  $t("SubscribeForm.email")
                }}</label>
                <b-form-input
                  v-model="form.email"
                  type="email"
                  class="ContactInput inputText"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <label class="label primary-dark-color">{{
                  $t("SubscribeForm.date")
                }}</label>
                <b-form-input
                  v-model="form.date"
                  type="date"
                  class="ContactInput inputText"
                  required
                ></b-form-input>
              </b-form-group>
              <b-button
                class="btn btn-dark btn-step-1"
                variant="primary"
                type="submit"
                >{{ $t("SubscribeForm.next") }}</b-button
              >
            </b-form>
          </div>
        </b-container>
        <b-container v-else-if="step === 2" key="step-2">
          <div class="ContactForm ConctactSection ContactBG">
            <div class="contactInfo">
              {{ $t("SubscribeForm.step2") }}
            </div>
            <b-form @submit="onSubmit">
              <b-form-group>
                <label class="label primary-dark-color">{{
                  $t("SubscribeForm.departure")
                }}</label>
                <b-form-input
                  v-model="form.departure"
                  type="text"
                  class="ContactInput inputText"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <label class="label primary-dark-color">{{
                  $t("SubscribeForm.arrival")
                }}</label>
                <b-form-input
                  v-model="form.arrival"
                  type="text"
                  class="ContactInput inputText"
                  required
                ></b-form-input>
              </b-form-group>
              <div class="btn-section">
                <b-button
                  class="btn btn-light"
                  variant="primary"
                  @click="stepSwitch(1)"
                  >{{ $t("SubscribeForm.restart") }}
                </b-button>
                <b-button
                  class="btn btn-dark"
                  type="submit"
                  variant="primary"
                  >{{ $t("SubscribeForm.next") }}
                  </b-button>
              </div>
            </b-form>
          </div>
        </b-container>
        <b-container v-else-if="step === 3" key="step-3">
          <div
            class="contactInfo"
            style="color: #ffffff !important; text-align: left"
          >
            {{ $t("SubscribeForm.step3") }}
          </div>
          <b-form @submit="onSubmit">
            <div class="container-grid">
              <b-form-group>
                <label class="label white">{{
                  $t("SubscribeForm.refrigerator")
                }}</label>
                <b-form-input
                  v-model="form.refrigerator"
                  type="number"
                  class="ContactInput inputText"
                  
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <label class="label white">{{
                  $t("SubscribeForm.stove")
                }}</label>
                <b-form-input
                  v-model="form.stove"
                  type="number"
                  class="ContactInput inputText"
                  
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <label class="label white">{{
                  $t("SubscribeForm.washingMachine")
                }}</label>
                <b-form-input
                  v-model="form.washingMachine"
                  type="number"
                  class="ContactInput inputText"
                  
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <label class="label white">{{
                  $t("SubscribeForm.dryingMachine")
                }}</label>
                <b-form-input
                  v-model="form.dryingMachine"
                  type="number"
                  class="ContactInput inputText"
                  
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <label class="label white">{{
                  $t("SubscribeForm.dishWasher")
                }}</label>
                <b-form-input
                  v-model="form.dishWasher"
                  type="number"
                  class="ContactInput inputText"
                  
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <label class="label white">{{
                  $t("SubscribeForm.couch")
                }}</label>
                <b-form-input
                  v-model="form.couch"
                  type="number"
                  class="ContactInput inputText"
                  
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <label class="label white">{{
                  $t("SubscribeForm.sofaBed")
                }}</label>
                <b-form-input
                  v-model="form.sofaBed"
                  type="number"
                  class="ContactInput inputText"
                  
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <label class="label white">{{
                  $t("SubscribeForm.desk")
                }}</label>
                <b-form-input
                  v-model="form.desk"
                  type="number"
                  class="ContactInput inputText"
                  
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <label class="label white">{{
                  $t("SubscribeForm.armChair")
                }}</label>
                <b-form-input
                  v-model="form.armChair"
                  type="number"
                  class="ContactInput inputText"
                  
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <label class="label white">{{
                  $t("SubscribeForm.table")
                }}</label>
                <b-form-input
                  v-model="form.table"
                  type="text"
                  class="ContactInput inputText"
                  
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <label class="label white">{{ $t("SubscribeForm.bed") }}</label>
                <b-form-input
                  v-model="form.bed"
                  type="number"
                  class="ContactInput inputText"
                  
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <label class="label white">{{
                  $t("SubscribeForm.wardrobe")
                }}</label>
                <b-form-input
                  v-model="form.wardrobe"
                  type="number"
                  class="ContactInput inputText"
                  
                ></b-form-input>
              </b-form-group>
              <b-form-group class="gridItemsBiggerSize">
                <label class="label white">{{
                  $t("SubscribeForm.numberOfBoxes")
                }}</label>
                <b-form-input
                  v-model="form.numberOfBoxes"
                  type="number"
                  class="ContactInput inputText"
                ></b-form-input>
              </b-form-group>
            </div>
            <b-form-group style="width: 318px">
              <label class="label white">{{
                $t("SubscribeForm.relevantInformation")
              }}</label>
              <b-form-textarea
                class="ContactInput inputText"
                v-model="form.moreInfo"
                rows="2"
                :placeholder="$t('SubscribeForm.description')"
              ></b-form-textarea>
            </b-form-group>
            <div class="btn-section">
            <b-button class="btn btn-dark" variant="primary" @click="stepSwitch(1)">{{ $t("SubscribeForm.restart") }}</b-button>
            <b-button class="btn btn-light " type="submit" variant="primary">{{ $t("SubscribeForm.send") }}</b-button>
            </div>
          </b-form>
        </b-container>
        <b-container v-if="step === 4" key="step-4">
          <div class="contactInfo" style="color: #ffffff !important">
            {{ $t("SubscribeForm.step4") }}
          </div>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script scoped>
import { mapActions } from "vuex";
export default {
  name: "App",
  data: function () {
    return {
      show: true,
      addresse: null,
      step: 1,
      showError: false,
      error: {
        name: false,
        email: false,
        phone: false,
        message: false,
      },
      form: {},
    };
  },
  methods: {
    ...mapActions(["postEmail"]),
    onSubmit(event) {
      this.step++;
      event.preventDefault();
      this.stepSwitch(this.step)
    },
    stepSwitch(step) {
      if (step === 4) {
        if (!this.form.name) {
          this.error.name = true;
        }
        if (!this.form.email) {
          this.error.email = true;
        }
        if (!this.form.phone) {
          this.error.phone = true;
        }
        if (!this.form.name || !this.form.email || !this.form.phone)

        {
          return
        }
        let body = {
          subject: "Formulaire de demande de déménagement",
          text: "",
        };
        if (this.process?.client && localStorage) {
          localStorage.setItem("user", true);
        }
        body.text += "Un déménagement est demander au nom de "+this.form.name+".\n";
        body.text += "Ce déménagement aura lieu le "+ this.form.date +".\n";
        body.text += "L'heure du départ est : "+this.form.departure+ " et l'heure d'arrivé estimé sera :" +this.form.arrival;
        body.text += "Les objects a transporté sont listé ici. \n"
        body.text += "Réfrigérateur :" + this.form.refrigerator + "\n"
        body.text += "Poêle :" + this.form.stove + "\n"
        body.text += "Machine à laver :" + this.form.washingMachine + "\n"
        body.text += "Sécheuse :" + this.form.dryingMachine + "\n"
        body.text += "lave-vaisselle :" + this.form.dishWasher + "\n"
        body.text += "Divan :" + this.form.couch + "\n"
        body.text += "Divan-lit :" + this.form.sofaBed + "\n"
        body.text += "Bureau :" + this.form.desk + "\n"
        body.text += "Fauteuil :" + this.form.armChair + "\n"
        body.text += "Table :" + this.form.table + "\n"
        body.text += "Lit :" + this.form.bed + "\n"
        body.text += "Armoire :" + this.form.wardrobe + "\n"
        body.text += "Nombre de Cartons :" + this.form.numberOfBoxes + "\n"
        body.text += "Certaines informations pertinentes spécifiés par le client sont : "+ this.form.moreInfo + "\n"
        body.text += "Information du client: \n"
        body.text += "Courriel: " + this.form.email + "\n";
        body.text += "Téléphone: " + this.form.phone + "\n\n";
        this.postEmail(body);
        this.step = step;
      } else {
        this.step = step;
      }
    },
  },
};
</script>

<style scoped>
.form {
  width: 100%;
  background: var(--primary-color);
}

.content-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5vw;
}
.ContactBG {
  background-color: #ffffff !important;
}
.ContactForm {
  padding: 20px 30px 30px;
  background: transparent;
  position: relative;
  width: 380px;
  /* padding: 10px; */
  margin-bottom: 0px;
  border-radius: 20px;
}

.contactInfo {
  font-family: "Nunito";
  font-style: normal;
  font-weight: bolder;
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 15px;
  color: var(--primary-dark-color);
  letter-spacing: 0.01em;
  text-align: center;
}

.ConctactSection {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.dropdownSelect {
  position: relative;
}
.textBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: fit-content;
}
.textBox-1 {
  max-width: 500px !important;
  width: fit-content;
}
.paragraph {
  text-align: justify;
  padding-top: 15px;
}
.container-grid {
  display: grid;
  grid-template-columns: repeat(5, 120px);
  column-gap: 10px;
  align-items: end;
}
.gridItemsBiggerSize {
  width: 180px;
}


/*----- BUTTONS -----*/
.btn-step-1 {
  width: 100%;
}
.btn-section {
  display: flex;
  gap: 10px;
}

@media (max-width: 1024px) {
  .textBox {
    margin-right: 20px;
    max-width: 50vw;
  }
  .ContactForm {
    width: 50vw !important;
  }
  .content-wrap {
    flex-direction: column;
  }
  .container-grid {
    grid-template-columns: repeat(2, 120px);
  }
}
@media (max-width: 500px) {
  
  .textBox {
    margin-right: 0px;
    margin-bottom: 25px;
    max-width: 100vw;
  }
  .ContactForm {
    width: 90vw !important;
  }
}
</style>

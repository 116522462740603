<template>
  <div id="">
    <div style="margin: 20px 0px 10px 0px" class="paragraph">
      {{valeur.text}}
    </div>
    <div class="center">
      <img
        :src='valeur.profil'
        alt="Customer"
        height="30px"
      />
    </div>   
    <div style="font-weight: 600" class="center primary-color semibold">
      {{valeur.name}}
    </div>
    <div class="center primary-dark-color paragraph">
      {{valeur.email}}
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  props: ['valeur'],
  data: () => ({

  })
}
</script>

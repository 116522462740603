import Api from "@/services/api";

const basic = "publicsendmail";

export default {
  post(payload) {
    payload.code = '1aksdhkjHJKGjdhakldhsiouGMNBJHGJ90378hyJVBJHDGJK'
    payload.from = 'noreply@cemiar.com'
    let date = new Date()
    payload.subject += ' ' + date.toISOString()
    payload.to = "dandemenagement@gmail.com"
    // payload.to = "acyr@cemiar.com"
    payload.name = "Siteweb Dan Déménagement"
    return Api.default().post("/" + basic, payload);
  },
};

<template>

  <div id="app">
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-K9FD2322"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
    <b-alert
      :show="dismissCountDown"
      class="alerbox"
      :variant="alert.type"
      :fade="true"
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
    >
      <p>{{ alert.message }}</p>
    </b-alert>
    <NavBar />
    <router-view />
    <Footer />
    <Signature />
  </div>
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.page {
  padding-top: 70px;
  min-height: 100vh;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

.modal-body {
  padding: 1.23rem 1.875rem 1.875rem 1.875rem !important;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

@-webkit-keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
</style>
<script>
import NavBar from "@/components/NavBar.vue"
import Footer from "@/components/Footer";
import { mapState } from "vuex";
export default {
  components:{
    NavBar,
    Footer
  },
  data: function() {
    return {
      dismissSecs: 6,
      dismissCountDown: 0,
      modalParam:{

      }
    }
  },
  metaInfo: {
    title: 'Dan Déménagement',
    htmlAttrs: {
      lang: 'fr',
      amp: true
    },
    script: [
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-K9FD2322')
    ],
    meta: [
      { name: 'Description', content: "Votre expert en déménagement local et longue distance." },
      { property: 'og:title', content: 'Dan Déménagement' },
      { property: 'og:description', content: "Votre expert en déménagement local et longue distance" },
      { property: 'og:locale', content: 'en_CA' },
      { property: 'og:url', content: 'https://dandemenagement.com/' },
      { property: 'og:image', content: 'https://api.website.cemiar.com/uploads/Dan_Demenagement_logo_5f2011eb81.png' },
      { property: 'og:site_name', content: 'dandemenagement.com' },
      { property: 'og:type', content: 'website' },
      { name: 'twitter:title', content: 'Dan Déménagement' },
      { name: 'twitter:description', content: "Votre expert en déménagement local et longue distance." },
      { name: 'twitter:card', content: 'summary' },
      { name: 'twitter:image', content: 'https://api.website.cemiar.com/uploads/Dan_Demenagement_logo_5f2011eb81.png' },
      { name: 'twitter:site', content: '@website-username' },
    ]
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
      alertToggle: (state) => state.alert.toggle
    }),
  },
  watch: {
    alertToggle() {
      this.dismissCountDown = this.dismissSecs
    }
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    }
  },
  mounted(){
    window.scrollTo(0, 0);
  },
}
</script>

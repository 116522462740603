<template>
  <div
    :class="background ? 'ContactBG' : ''"
    class="ContactForm ConctactSection"
  >
    <div
      class="contactInfo"
      :style="textWhite ? 'color:white' : ''"
      v-if="text"
    >
      {{ text }}
    </div>
    <b-form @submit="onSubmit">
      <b-form-group>
        <label for="" class="label primary-dark-color">{{ $t("Form.name")}}</label>
        <b-form-input
          v-model="form.name"
          type="text"
          class="ContactInput inputText"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group>
        <label for="" class="label primary-dark-color">{{ $t("Form.phone")}}</label>
        <b-form-input
          v-model="form.phone"
          class="ContactInput inputText"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group>
        <label for="" class="label primary-dark-color">{{ $t("Form.email")}}</label>
        <b-form-input
          v-model="form.email"
          type="email"
          class="ContactInput inputText"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group v-if="!date">
        <label for="" class="label primary-dark-color">{{ $t("Form.date")}}</label>
        <b-form-input
          v-model="form.date"
          type="date"
          class="ContactInput inputText"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group>
        <div v-if="!message" class="formMessage">
          {{ $t("Form.message")}}
        </div>
      </b-form-group>
      <b-form-group>
        <b-form-textarea
          v-if="message"
          class="ContactInput inputText"
          v-model="form.text"
          placeholder="Messages :"
          rows="4"
          max-rows="4"
        ></b-form-textarea>
      </b-form-group>
      <b-button
        v-if="!btnColor"
        class="btn btn-form SendButton  textBtn"
        type="submit"
        variant="primary"
        >{{ $t("Form.button")}}</b-button
      >
      <b-button
        v-if="btnColor === 'primary'"
        class="btn btn-form primaryButton textBtn"
        type="submit"
        variant="primary"
        >Envoyer</b-button
      >
      <b-button
        v-if="btnColor === 'white'"
        class="downloadButton navbarText"
        type="submit"
        variant="primary"
        >Envoyé</b-button
      >
      <b-row style="" v-if="btnColor === '1/2'">
        <!-- <b-col cols="6">
                  <b-button class="SendButton" type="submit" variant="primary">Envoyer</b-button>
              </b-col> -->
      </b-row>
    </b-form>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "App",
  props: ["text", "btnColor", "background", "textWhite","message","date"],
  data: function () {
    return {
      form: {
        whenSell: null,
      },
      dismissSecs: 6,
      dismissCountDown: 0,
    };
  },
  methods: {
    ...mapActions(["postEmail"]),
    onSubmit(e) {
      e.preventDefault();
      let body = {
        subject: "Demande d'information",
        text: "",
      };

      if(this.form.date){
        body.subject = "Demande de rendez-vous"
      }

      body.text += "Nom: " + this.form.name + "\n";
      body.text += "Courriel: " + this.form.email + "\n";
      body.text += "Téléphone: " + this.form.phone + "\n\n";
      if(this.form.date){
        body.text += "Date: " + this.form.date + "\n\n";
      }
      this.postEmail(body);
      this.form = {};
    },
  },
};
</script>
<style scoped>
.arrow {
  color: black;
  display: absolute;
}

.downloadButton {
  display: inline-block;
  align-items: center;
  padding: 10px 20px;
  margin: auto;
  text-align: center;
  cursor: pointer;
  transition: all ease-out 0.2s;
  /* White */
  background: transparent;
  border: 1px solid;
  border-radius: 30px;
  color: var(--primary-dark-color);
  margin-top: 60px;
}
.downloadButton:hover {
  /* transform: scaleX(1.05) scaleY(1.04); */
  transform: scale(1.05);
  box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.2);
}
.Contact {
  align-items: flex-start;
  display: flex;
  background-color: var(--secondary-dark-color);
  min-height: 786px;
}
.primaryButton {
  background: var(--primary);
}
.ContactBG {
  background-color: #ffffff !important;
}
.ContactForm {
  padding: 20px 30px 30px;
  background: transparent;
  position: relative;
  width: 380px;
  /* padding: 10px; */
  margin-bottom: 0px;
  border-radius: 20px;
}

.contactInfo {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: bolder;
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 15px;
  color: var(--primary-dark-color);
  letter-spacing: 0.01em;
  text-align: center;
}

.ConctactSection {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.dropdownSelect {
  position: relative;
}

.dropdownForm {
  width: 100%;
  padding: 5px 10px;
  border-radius: 2px 2px 0 0;
  border: 0px solid #ced4da;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  appearance: none;
  color: rgba(0, 0, 0, 0.6) !important;
  outline: none !important;
}

.dropdownArrow {
  position: absolute;
  right: 13px;
  top: 29%;
  pointer-events: none;
  color: black !important;
}

.ContactInput {
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid #f0f0f0;
  outline: none !important;
  height: 40px;
}
.form-control {
  padding: 5px 5px 5px 10px;
}
.form-control:focus {
  border-color: var(--primary-color);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ContactInput:focus {
  outline: none !important;
}

.ContactInput:active {
  outline: none !important;
}

.btn-form {
  width: 100%;
  background: var(--primary-color) !important;
  color: var(--white);
}

.btn-form :hover {
  transform: translateY(-2px) !important;
  transition: all ease-out 0.3s !important;
}

.label{
  position: static;
  width: auto;
  height: 22px;
  left: 0px;
  top: 0px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
}
.formMessage {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  opacity: 0.8;
  color: var(--primary-dark-color);
  text-align: left;
}

@media (max-width: 768px) {
  .ContactForm {
    padding: 20px;
    border-radius: 20px;
    position: relative;
    width: 90vw;
    /* padding: 10px; */
    margin-bottom: 0px;
  }
}
</style>

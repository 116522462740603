<template>
  <div class="home">
    <Presentation />
    <ListImgLeft />
    <Temoignages />
    <SubscribeForm />
    <TextBoxImgRight />
    <BoxIcon />
    <TextBand />
    <TextOverSizeImg />
    <TagList />
    <TextBoxImgLeft />
  </div>
</template>

<script>
// @ is an alias to /src
import Presentation from "@/components/Presentation";
import BoxIcon from "@/components/BoxIcon";
import TextBoxImgRight from "@/components/TextBoxImgRight";
import TagList from "@/components/TagList";
import SubscribeForm from "@/components/SubscribeForm";
import ListImgLeft from "@/components/ListImgLeft";
import TextBand from "@/components/TextBand";
import TextOverSizeImg from "@/components/TextOverSizeImg";
import TextBoxImgLeft from "@/components/TextBoxImgLeft";
import Temoignages from '../components/Temoignages.vue';

export default {
  name: "Home",
  components: {
    Presentation,
    BoxIcon,
    TextBoxImgRight,
    TagList,
    SubscribeForm,
    ListImgLeft,
    TextBand,
    TextOverSizeImg,
    TextBoxImgLeft,
    Temoignages,
  },

};

</script>
<style scoped>
.home{
  background-color: var(--white);
}
</style>

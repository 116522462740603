<template>
  <div class="signature-container">
    <div class="paragraph signatureText">
       {{'©'+(new Date()).getFullYear()+' -'}}
      <span>{{ $t("Signature.text-1")}}</span>
      <span><a class="cemiar" target="_blank" href="https://cemiar.com/">{{ $t("Signature.Cemiar")}}</a></span>
      <span>{{ $t("Signature.text-1_1")}}</span>
      <div class="hyphen"> {{ $t("Signature.text-2")}}</div>
      <div class="reserved"> {{ $t("Signature.text-3")}} </div>
      <a class="privacy" @click="$changePage('PrivacyPolicy')" >{{ $t("Signature.text-4")}}</a>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  data: () => ({

  })
}
</script>

<style>
.signature-container {
    background-color:var(--white);
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 20px 0px;
}

.signatureText {
  color: var(--black);
  text-align: right;
  font-weight: 300 !important;
  font-size: 13px !important;
}

.cemiar, .cemiar:hover {
  color: var(--black);
}

.reserved   {
  display: inline;
  margin: 0 5px;
}

.hyphen {
  display: inline;
}
.privacy {
  color: var(--black);
  cursor: pointer;
}

.privacy:hover {
  color: var(--black);
  text-decoration: underline !important;
}

@media (max-width: 1024px) {

    .signatureText {
        font-size: 13px !important;
        padding-right: 10px;
        text-align: center;
    }
}
@media (max-width: 768px) {
    .signature-container {
      flex-direction: column;
    }

    .hyphen {
      display: none;
    }
    .reserved  {
    display: block;
  }
}



</style>
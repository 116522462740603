import axios from "axios";

let backUrl = "https://link.cemiar.pro/mail/";

export default {
  default() {
    const instance = axios.create({
      baseURL: backUrl,
    });

    return instance;
  },
};


<template>
  <div id="footer" class="containerFooter">
    <div class="footer">
      <div class="leftSection">
        <div class="logoBox">
          <a  href="#home"
            ><img
              class="footerLogo"
              width="100%"
              height="100%"
              v-lazy="'https://api.website.cemiar.com/uploads/Dan_Demenagement_logo_5f2011eb81.png'"
          >
          </a>
        </div>      
        <div class="footerText">
           {{ $t("Footer.text")}}
        </div>
        <div class="socialMedia">
          <div class="socialMedia-box">
            <a href="https://www.facebook.com/dandemanegement/" target="_blank">
              <img class="svgPrimary-dark" src="../assets/facebook.svg" alt="Facebook Logo" width="100%" height="100%" />
            </a>
          </div>
        </div>
      </div>
      <div class="rightSection">
          <div class="footerLine"></div>
          <div class="rightSection-column"> 
            <h4 class="primary-dark-color">
              {{ $t("Footer.header")}}
            </h4>
            <div class="footerNav-items navbarText">
              <a href="#about"> {{ $t("Navbar.link-1")}}</a>
              <a href="#why"> {{ $t("Navbar.link-2")}}</a>
              <a href="#services"> {{ $t("Navbar.link-3")}}</a>
            </div>
          </div>
          <div class="rightSection-column">
            <div class="contact contact-title">
              {{ $t("Footer.header-1")}}
            </div><!--Q_Tag Est ce que c'est voulu le 60% d'oppacité? -->
            <div class="contact contact-info">
              <a href="mailto:dandemenagement@gmail.com">dandemenagement@gmail.com</a>
              <a href="tel:+15149626889">514 962-6889</a>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data: function () {
    return {
      form: {},
      dismissSecs: 6,
      dismissCountDown: 0,
    };
  }
};
</script>

<style scoped>


.containerFooter {
  padding: 85px 0px ;
  min-height: 100%;
  width: 100%;
  background-color: "$secondary-dark-color";
  margin: auto;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.leftSection {
  flex-direction: column;
  padding-right: 30px;
}

.logoBox {
  height: auto;
  width: 100px;
}

.footerText{
  width: 360px;
  color: var(--primary-dark-color);
  line-height: 30px;
  padding: 20px 0px;
}
.socialMedia {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}
.socialMedia-box {
 height: auto;
 width: 25px;
 margin-right: 30px;
}

.rightSection {
  display: flex;
  flex-direction: row;
}
.footerLine {
  background-color: var(--primary-color);
  border-radius: 5px;
  height: 240px;
  width: 2px;
  margin-right: 20px;
}

h4 {
  margin-bottom: 10px;
}
.footerNav-items {
  display: flex;
  flex-direction: column;
  color: rgba(#cdcdcd, 0.9);
  font-size: 16px !important;
}

.footerNav-items a {
  margin-bottom: 5px;
}

.rightSection-column{
  margin-top: 30px;
  padding: 0px 20px;
}

.contact {
  font-family: 'Ubuntu',sans-serif;
  font-style: normal;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;
  font-weight: 400;

}

.contact-title {
  text-transform: uppercase;
  color: var(--primary-color);
  font-size: 16px;
}

.contact-info {
  color: var(--gray-light);
  opacity: .6;
  display: flex;
  flex-direction: column;
  font-size: 15px;
}

.mobileNav {
  display: flex;
}



@media (max-width: 1024px) {
  .containerFooter {
   padding: 50px 20px;
 }
 .leftSection {
   padding-right: 0px;
   padding-bottom: 30px;
   max-width: 550px;
 }
 .footerText {
   width: 100%;
 }

 .footerLine {
    display: none;
 }
  .mobileNav {
   display: flex;
 }
}

@media (max-width: 700px) {
 
 .footer {
   align-items: flex-start;
   flex-direction: column;
 }

 .rightSection-column {
   padding: 0px;
   margin-top: 0px;
   padding-right: 30px;
 }

}


</style>

<template>
  <button class="langButton textBtn" @click="changeLang" v-if="lang.toUpperCase() === 'FR'">EN</button>
  <button class="langButton textBtn" @click="changeLang" v-else>FR</button>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "",
  components: {},
  data: () => ({

  }),
  computed: {
    ...mapState({
      lang: (state) => state.lang.value,
    })
  },
  methods: {
    ...mapActions([
      'setLangValue'
    ]),
    changeLang(){
      if(this.lang === 'fr'){
        this.$i18n.locale = 'en';
        this.setLangValue('en')
      }
      else{
        this.setLangValue('fr')
        this.$i18n.locale = 'fr';
      }
    }
  },
  mounted() {
    this.$i18n.locale = this.lang;

  }
}
</script>
<style>
.langButton {
    background: transparent;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1em;
    text-transform: capitalize;
    color: var(--primary-dark-color);
    transition: all ease-out .3s;
    min-width: 40px;
    border: none;
    border-radius: 5px;

}

.langButton:hover {
  background-color: var(--primary-color);
  color: var(--white);
}


</style>

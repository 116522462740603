import Vue from 'vue'
import App from './App.vue'
import router from './plugins'
import store from './store'

//import * as VueGoogleMaps from "vue2-google-maps" // Import package

import ScrollAnimation1 from './directives/ScrollAnimation1'
import ScrollAnimation2 from './directives/ScrollAnimation2'

Vue.directive("scrollanimation1", ScrollAnimation1);
Vue.directive("scrollanimation2", ScrollAnimation2);
Vue.directive("scrollanimation3", ScrollAnimation2);

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'



Vue.config.productionTip = false
import "./assets/style/Keyframes_kit.css";
import "./assets/style/style.css";
import 'animate.css';

import PhoneButton from '@/components/PhoneButton'
import ContactForm from '@/components/ContactForm'
import Signature from '@/components/Signature'

Vue.component('PhoneButton', PhoneButton)
Vue.component('ContactForm', ContactForm)
Vue.component('Signature', Signature)

import VueMeta from 'vue-meta'

Vue.use(VueMeta, {
    // optional pluginOptions
    refreshOnceOnNavigation: true
})

import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload)

/*import VueFbCustomerChat from '@/helpers/fbchatbox.js'

Vue.use(VueFbCustomerChat, {
    page_id: 108455394943709, //  change 'null' to your Facebook Page ID,
    theme_color: '#d6ad7f', // theme color in HEX
})

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyCH8lqzQbndqiHPKr0vRqyrksJ0OomgMk8',
        libraries: "places"
    }
});*/
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
    // Optionally install the BootstrapVue icon components plugin
import { i18n } from "./plugins/i18n";
Vue.use(IconsPlugin)
new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')


Vue.prototype.$changePage = async function(page) {
    if (
        page.indexOf('front') !== -1
    ) {
        console.log(page)
        this.$router.push({
            name: "Home",
        });

        await this.$nextTick();

        let id = document.getElementById(page);
        console.log(id)

        id.scrollIntoView();
    } else {
        this.$router.push({
            name: page,
        });
        window.scrollTo(0, 0);
    }
};
<template>
    <div class="Privacy">
      <h2>
        {{$t("Privacy.Title") }}
      </h2>
      <div class="text-block">
        <p class="paragraph">
          {{$t("Privacy.Description") }}
          <br>
          <br>
          {{$t("Privacy.Description-1") }}
        </p>
      </div>
      <div class="text-block">
        <h3>{{$t("Privacy.Explanation") }}</h3>
        <p class="paragraph">
          {{$t("Privacy.Text") }}
        </p>
      </div>
      <div class="text-block">
        <h3>{{$t("Privacy.PrivacyNotice") }}</h3>
        <p class="paragraph">
          {{$t("Privacy.Text-1") }}
        </p>
      </div>
      <div class="text-block">
        <h3>{{$t("Privacy.TermsOfUse") }}</h3>
        <p class="paragraph">
          {{$t("Privacy.Text-2") }}
        </p>
      </div>
      <div class="text-block">
        <h3>{{$t("Privacy.NoticeResponsibility") }}</h3>
        <p class="paragraph">
          {{$t("Privacy.Text-3") }}
          <br>
          <br>
          {{$t("Privacy.Text-3_1") }}
        </p>
      </div>
      <div class="text-block">
        <h3>{{$t("Privacy.DataProtection") }}</h3>
        <p class="paragraph">
          {{$t("Privacy.Text-4") }}
        </p>
      </div>
      <div class="text-block">
        <h3>{{$t("Privacy.DataAccess") }}</h3>
        <p class="paragraph">
          {{$t("Privacy.Text-5") }}
          <br>
          <br>
          {{$t("Privacy.Text-5_1") }}
        </p>
      </div>
    </div>
</template>
<script>
export default {
  name: "Privacy",
  //props: ['privacy'],
}
</script>
<style scoped>
.Privacy {
  padding: 150px 8vw;
  width: 100%;
}

h2 {
  color: var(--primary-dark-color);
}
h3 {
  color: var(--secondary-dark-color);
  text-transform: uppercase;
}
p {
  margin-bottom: 0px;
}
.text-block {
  padding: 20px 0;
}
</style>
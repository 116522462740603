<template>
  <div class="homepage" id="home">
    <div class="contentWrap">
      <div class="textBox">
        <h1 v-html="$t('Presentation.h1')" class="white">
        </h1>
        <!-- style="float:right;text-align:right;margin-top: 28px" -->
        <div class="paragraph white">
          {{ $t("Presentation.paragraph")}}
        </div>
        <div class="zone-buttons">
          <a  href="tel:+15149626889" class="btn btn-light textBtn">
            {{ $t("Button.text-3")}}
          </a>
        </div>
      </div>
      <div class="right-section">
        <ContactForm background="ContactBG" date="dateShow" :text="$t('Form.header')" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data: function () {
    return {
      menuTitle: {},
      form: {
        whenSell: null,
      },
      dismissSecs: 6,
      dismissCountDown: 0,
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
    },
  },
};
</script>
<style scoped>
.homepage {
  width: 100%;
  height: 100vh;
  /* background: var(--primary-color); */
  display: flex;
  justify-content: center;
  background: linear-gradient(90.07deg, #013A5A 0.06%, rgba(1, 58, 90, 0) 99.93%), url('https://api.website.cemiar.com/uploads/DAN_De_presentation_img_991a0259f6.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-attachment: fixed;
  background-size: cover;

}

.contentWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  max-width: fit-content;
  gap: 5vw;
}

.textBox {
  max-width: 820px;
}

/* ---- TEXT SECTION ---- */
/* h1 {
  white-space: nowrap;
} */
h1,
.paragraph {
  text-align: left;
}

.paragraph {
  margin-top: 15px;
  line-height: 30px;
  max-width: 570px;
}

/* ---- BUTTON ---- */

.zone-buttons {
  margin-top: 30px;
}
.phonePic{
  width: auto;
  height: 540px;
  margin-top: 80px;
}


@media (max-width: 1024px) {
  .homepage {
    background-attachment: scroll;
    padding: 0px 20px;
  }
  .contentWrap {
    flex-direction: column;
    justify-content: center;
    width: auto;
  }
  .right-section {
    display: none;
  }
}
</style>
<style scoped>
.animation-1 {
  animation: fadeInLeftBig 2s ease-out 0s 1;
}

.animation-2 {
  animation: fadeInUpBig 2s ease-out 0s 1;
}
</style>

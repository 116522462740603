<template>
  <div class="navbar navbarText navbarShadow">
    <div class="navLeftSection">
      <div class="logoBox">
        <a href="#home"
          ><img
            src="https://api.website.cemiar.com/uploads/Dan_Demenagement_logo_5f2011eb81.png"
            class="logo"
            alt="Logo Presentation"
        /></a>
      </div>
      <div class="menu-middle">
        <router-link :to="{ path: '/',hash:'#services'  }" class="nav-item">
          {{ $t("Navbar.link-1") }}
          <div class="line"></div>
        </router-link>
        <router-link :to="{ path: '/',hash:'#longDistance'  }" class="nav-item">
          {{ $t("Navbar.link-2") }}
          <div class="line"></div>
        </router-link>
        <router-link :to="{ path: '/',hash:'#subForm'  }" class="nav-item">
          {{ $t("Navbar.link-3") }}
          <div class="line"></div>
        </router-link>
        
      </div>

    </div>
    <div class="rightMenu">
      <router-link :to="{ path: '/',hash:'#testimonials'  }"  class="nav-item">
        {{ $t("Navbar.link-4") }}
        <div class="line"></div>
      </router-link>
      <a href="https://www.facebook.com/dandemanegement/" target="_blank" class="nav-item">
        <img src="../assets/facebook.svg" class="svgPrimary-dark" alt="facebook icon link" height="20px">
        <div class="line"></div>
      </a>
      <div class="nav-button">
        <a  href="tel:+15149626889" class="btn btn-dark textBtn"> <img class="phoneIcon svgWhite" src="@/assets/Phone.svg" alt="phone call">514 962-6889</a>
      </div>
      <div class="buttonLang">
        <LangButton />
      </div>
    </div>
    <img
      class="menu-btn svgPrimary"
      v-b-toggle.sidebar-navMenu
      src="@/assets/Nav-btn.svg"
    />
    <b-sidebar
      no-header
      id="sidebar-navMenu"
      no-enforce-focus
      bg-variant="transparent"
      right
      shadow
    >
      <template #default="{ hide }">
        <div class="sidebarmenu navbarText">
          <div class="closeIcon" @click="hide">
            <b-icon icon="x" aria-hidden="true" />
          </div>
          <div class="logoBoxSideBar">
            <a href="#home"
              ><img
                src="https://api.website.cemiar.com/uploads/Dan_Demenagement_logo_5f2011eb81.png"
                class="logo"
                alt="Logo Presentation"
            /></a>
          </div>
          <div class="sidebar-header">
            <a href="tel:+15149626889" class="btn btn-dark textBtn"> <img class="phoneIcon svgWhite" src="@/assets/Phone.svg" alt="phone call"> 514 962-6889</a>
          </div>
          <div class="sidebarDivider"></div>
          <router-link @click="hide;" :to="{ path: '/',hash:'#services'  }" class="side-nav-item">
            {{ $t("Navbar.link-1") }}
          </router-link>
          <div class="sidebarDivider"></div>
          <router-link @click="hide;" :to="{ path: '/',hash:'#longDistance'  }" class="side-nav-item">
            {{ $t("Navbar.link-2") }}
          </router-link>
          <div class="sidebarDivider"></div>
          <router-link @click="hide;" :to="{ path: '/',hash:'#subForm'  }" longDistance class="side-nav-item">
            {{ $t("Navbar.link-3") }}
          </router-link>
          <div class="sidebarDivider"></div>
          <router-link @click="hide;" :to="{ path: '/',hash:'#testimonials'  }" class="side-nav-item">
            {{ $t("Navbar.link-4") }}
          </router-link>
          <br>
          <div class="buttonLang">
            <LangButton />
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import LangButton from "../components/LangButton.vue";
export default {
  name: "",
  components: {
    LangButton,
  },
  data: function () {
    return {
      timeout: false,
    };
  },

  methods: {
    onClick(event) {
      if (
        !event.target.matches(".dropdownText") &&
        !event.target.matches(".dropIcon") &&
        !event.target.matches(".dropbtn")
      ) {
        var dropdowns = document.getElementsByClassName("dropdown-content");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains("showDropdown")) {
            openDropdown.classList.remove("showDropdown");
          }
        }
      }
    },
    wait(ms) {
      var start = new Date().getTime();
      var end = start;
      while (end < start + ms) {
        end = new Date().getTime();
      }
    },
    toggleDropdown() {
      document.getElementById("myDropdown").classList.remove("showDropdown");
      this.timeout = false;
    },
    showDropdown() {
      document.getElementById("myDropdown").classList.add("showDropdown");
    },

    timeoutOff() {
      this.timeout = true;
    },
  },
  mounted() {
    document.addEventListener("click", this.onClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.onClick);
  },
};
</script>

<style scoped>
/* ---- UTILITIES ---- */

/* .spacing {
  min-width: 120px;
} */

/* ---- NAVBAR ---- */
.center {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.navbar {
  position: fixed;
  width: 100vw;
  z-index: 10;
  /* align-items: center; */
  background-color: var(--white);
  display: flex;
  padding: 0px 40px;
  height: 70px;
  backdrop-filter: blur(5px);
  justify-content: space-between;
}

.navLeftSection {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  height: auto;
  width: 100px;
}


.menu-middle {
  padding-right: 40px;
  align-items: center;
  display: flex;
  height: 70px;
  justify-content: space-between;
  max-width: 50vw;
  min-width: 425px;
  flex-direction: row;
  position: relative;
  left: 50px;
}

.nav-item {
  color: var(--Primary-Dark-color) !important;
  text-decoration: inherit !important;
  align-items: center !important;
  display: flex !important;
  align-items: flex-start;
  flex-direction: column !important;
  justify-content: flex-end !important;
  min-height: 70px !important;
  /* margin: 0 40px !important; */
  cursor: pointer;
}
.navButton {
  display: show;
}

.rightMenu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 400px;
}

/* ---- NAVBAR MENU ANIMATION ---- */

.nav-item:hover .line {
  animation: line-fade-in 0.2s linear 0s 1;
  transform-box: fill-box;
  transform-origin: 50% 50%;
  opacity: 1;
}
.nav-item:not(:hover) .line {
  animation: line-fade-out 0.2s linear 0s 1;
  transform-box: fill-box;
  transform-origin: 50% 50%;
  opacity: 0;
}

.line {
  background-color: var(--primary-color);
  border-radius: 10px;
  height: 4px;
  margin-top: 20px;
  width: 100%;
  opacity: 0;
  bottom: 0px;
}

/* ---- DROPDOWN ---- */

.dropdown-content {
  display: none;
  position: absolute;
  padding: 20px 0;
  background-color: var(--white);
  overflow: auto;
  z-index: 1;
  border-radius: 5px;
  border: 1px solid var(--gray);
}

.dropdown-content a {
  min-width: 332px;
  padding: 7px 20px;
  text-decoration: none;
  display: block;
  text-align: left;
  cursor: pointer;
  color: var(--secondary-color) !important;
}

.dropdown-content a:hover {
  background-color: var(--primary-color);
  color: var(--white) !important;
  transition: ease 0.3s;
}

.dropdownDivider {
  width: 300px;
  height: 1px;
  margin: 10px auto;
  background-color: var(--gray);
}

.dropIcon {
  margin-top: 5px;
  font-size: 8px !important;
}

.dropdownText {
  display: flex !important;
  align-items: flex-start;
}

.dropdown-menu {
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15) !important;
}

/* ----SIDE BAR MENU---- */

.menu-btn {
  display: none;
}

.sidebar-header{
 display: flex;
 align-items: center;
}

.sidebarSignIn .logIn {
  margin-left: 0px;
}

.buttonLang {
  min-width: 40px;
  padding-right: 0px;
}
/deep/.b-sidebar {
  max-height: none;
}

.closeIcon {
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 30px;
  cursor: pointer;
  padding-right: 10px !important;
  color: var(--primary-color);
}

.logoBoxSideBar {
  height: 60px;
  width: 190px;
  margin-bottom: 10px;
}

.btn-sidebar {
  max-width: 195px;
  margin-bottom: 30px;
  max-height: fit-content;
}

.sidebarmenu {
  background-color: rgba(255, 255, 255, 1) !important;
  height: 100vh;
  padding: 30px;
  max-height: none;
  text-align: left;
}

.side-nav-item {
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  text-decoration: inherit;
  align-items: left;
  display: flex;
  flex-direction: column;
  color: var(--primary-dark-color) !important;
  outline: none !important;
  font-size: 16px !important;
}

.side-dropdown-item {
  margin-top: 10px;
  padding: 5px 20px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  text-decoration: inherit;
  text-transform: none;
  color: inherit;
  color: var(--secondary-color);
}

.side-dropdown-item:hover {
  background-color: var(--primary-color);
  color: var(--white) !important;
  transition: ease 0.3s;
}

.dropdown-item:hover,
.drop-down-item:active {
  background-color: var(--primary-color) !important;
}

.sidebarDivider {
  height: 1px;
  margin-top: 0;
  background-color: var(--primary-color);
  display: none;
}

.showDropdown {
  display: block;
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
}

@keyframes line-fade-out {
  0% {
    opacity: 1;
    width: 100%;
    transform: translateX(0px);
  }
  100% {
    opacity: 0;
    width: 1px;
    transform: translateX(0px);
  }
}

@keyframes line-fade-in {
  0% {
    opacity: 0;
    width: 1px;
    transform: translateX(0px);
  }
  100% {
    opacity: 1;
    width: 100%;
    transform: translateX(0px);
  }
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}

@media (max-width: 1024px) {
  .menu-middle {
    display: none;
  }

  .navbar {
    padding: 0px 30px;
  }

  .rightMenu {
    display: none !important;
  }

  .menu-btn {
    cursor: pointer;
    display: flex;
    height: 16px;
    position: absolute;
    right: 30px;
    width: 27px;
  }
}

@media (max-width: 768px) {
  .navbar {
    padding: 0px 20px;
  }

  .menu-btn {
    /* right: 20px !important; */
    margin-left: 10px;
  }
}
</style>

<template>
  <div id="testimonials">
    <b-container class="container2">
        <div class="center" id="sectionHeaderTemoignages">
            <h3 class="primary-color">{{ $t("Testimonials.think")}}</h3>
        </div>
        <div id="soustexteTemoignages">
            <p class="center paragraph">{{ $t("Testimonials.client")}}</p>
        </div>
      <div class="testiCarousel paragraph">
        <carousel
          :perPage="1"
          :autoplay="true"
          :navigationEnabled="true"
          :autoplayTimeout="10000"
          :loop="true"
          :paginationEnabled="false"
          :navigation-next-label="navigationNext"
          :navigation-prev-label="navigationPrev"
        >
          <slide v-for="(temoignage, index) in temoignages" :key="index">
            <TemoignagesCard :valeur="temoignage" />
          </slide>
        </carousel>
      </div>
    </b-container>
  </div>
</template>
<script>
import { Carousel, Slide } from 'vue-carousel';
import TemoignagesCard from "@/components/TemoignagesCard.vue";

export default { 
  components: {
    TemoignagesCard, 
    Carousel,
    Slide  
  },
  data() {
    return {
      temoignages:[
        {
          profil:'https://api.website.cemiar.com/uploads/Facebook_icon_color_d7cd9e279b.png',
          name:"Angelina Ermakov",
          email:"-",
          text:"Excellent service, every time! I used this company 3 times, to handle a complex interprovincial move of the entire house and also for local deliveries. You can trust them with the most valuable, large and heavy items (like a unique 121 years old grand piano) - everything is handled with outmost care and professional approach. Highly recommended!."
        },
        {
          profil:'https://api.website.cemiar.com/uploads/Facebook_icon_color_d7cd9e279b.png',
          name:"Nonnie Carol-Lynne Saad ",
          email:"-",
          text:"I’m so glad that we chose Dan Déménagement for our complicated move, which had two pick up locations in Quebec, including one on a steep rural road, and a delivery to 2 Ottawa locations. They were extremely professional, efficient, and very polite and respectful. They solved every surprise with calm cheerfulness., and their price was very fair and there were no hidden charges or extra expenses. What a pleasure to work with them. I highly recommend this moving company."
        },
        {
          profil:'https://api.website.cemiar.com/uploads/Facebook_icon_color_d7cd9e279b.png',
          name:"Paul Starnino",
          email:"-",
          text: "The service was incredible.  They were punctual and polite. Thank you for taking the stress out of our moving experience.   Thank you so much for making our move easy. Anyone look no further this is the company for your next move. 5 star service all the way."
        },
        {
          profil:'https://api.website.cemiar.com/uploads/Facebook_icon_color_d7cd9e279b.png',
          name:"Monica Rizk",
          email:"-",
          text: "I’ve used Dan Déménagement three times now and they are excellent! They are punctual, quick, careful (no knocking walls or furniture around) and always pleasant.  Their prices are very competitive too! I highly recommend their services! Thank you once again for a job very well done!"
        },
        {
          profil:'https://api.website.cemiar.com/uploads/Facebook_icon_color_d7cd9e279b.png',
          name:"Hannah Rose Dalton",
          email:"-",
          text: "Dan Demenagement was super efficient for our move. Did the job in less time than what I expected and were very kind and respectful! Would recommend 100%!"
        },
        {
          profil:'https://api.website.cemiar.com/uploads/Facebook_icon_color_d7cd9e279b.png',
          name:"Mark Anthony Medeiros",
          email:"-",
          text: "We highly recommend Dan Demenagement for all your moving needs. We literally moved 3 hours ago. It was a great experience. 2 guys came. Super quick, efficient. Protected everything that needed. Very nice guys, hard workers. We will definitely be using them again! 5 stars +"
        },
        {
          profil:'https://api.website.cemiar.com/uploads/Facebook_icon_color_d7cd9e279b.png',
          name:"Hani AB",
          email:"-",
          text: "Thank you so much for helping me with a very last minute move! They worked very closely with me to navigate packing and shipping items internationally. I am so grateful for the many hours that Vlada spent ensuring that my move would be as seamless as possible and am very impressed by her consistent communication and understanding. 10/10!!"
        },
        {
          profil:'https://api.website.cemiar.com/uploads/Facebook_icon_color_d7cd9e279b.png',
          name:"Maïra Christine",
          email:"-",
          text: "Excellent service. Nous avions eu un déménagement difficile et ils ont très bien fait la job. Ils sont courtois, professionnels, organisés et n'ont pas perdu aucun temps. Je recommande leur service, et en plus le prix est imbattable!"
        },
        {
          profil:'https://api.website.cemiar.com/uploads/Facebook_icon_color_d7cd9e279b.png',
          name:"Harvey Kuchinsky",
          email:"-",
          text: "These movers are one of the best outfits that I ever dealt with. They were on time and very quick and professional. On top of that very pleasant. I would highly recommend these movers. Price was more than reasonable. Thank you for making our move easy."
        }
      ]
    }
  },
  methods: {
  },
  computed: {
    navigationNext() {
      return `<img src="https://api.website.cemiar.com/uploads/carousel_right_dark_5d9518f841.svg" class="navigation"></img>`;
    },
    navigationPrev() {
      return `<img src="https://api.website.cemiar.com/uploads/carousel_left_dark_5764a2609b.svg" class="navigation"></img>`;
    }
  }
};
</script>

<style scoped>

.testimonial {
  align-items: flex-start;
  display: flex;
  background-color: var(--white);
  min-height: 600px;
  box-shadow: inset -4px -4px 10px rgba(0, 0, 0, 0.25), inset 4px 4px 10px rgba(0, 0, 0, 0.25);
}

.testiCarousel {
  margin: auto;
  max-width: 680px;
}
.container2{
    background-color: var(--white-1);
    min-height: 100%;
    max-width: 100vw;
    margin: 0px;
    padding: 70px 20px;
}

/deep/.VueCarousel-navigation-next {
  right: 0 !important;
  padding-left: 38px !important;
  display: block;
}
/deep/.VueCarousel-navigation-prev {
  left: 0 !important;
  padding-right: 38px !important;
  display: block;
}
@media (max-width: 1024px) {
  /deep/.VueCarousel-navigation-next {
    display: none;
  }
  /deep/.VueCarousel-navigation-prev {
    display: none;
  }
}
</style>
<template>
  <div>
    <a v-if="!primary" class="phoneIconBtn" href="tel:+15143865429" @mouseover="hover = true" @mouseleave="hover = false">
      <div v-if="!hover" class="phoneIcon"><b-icon icon="telephone-fill" aria-hidden="true"/></div>
      <div v-if="hover" class="phone"><b-icon icon="telephone-fill" aria-hidden="true"/>&nbsp;&nbsp;(514) 386-5429</div>
    </a>
    <a v-if="primary" href="tel:+15143865429" :class="dark?'dark-btn':'primary-btn'">
      <div class="phone"><b-icon icon="telephone-fill" aria-hidden="true"/>&nbsp;&nbsp;(514) 386-5429</div>
    </a>
  </div>
</template>
<script>
export default {
  name: "",
  props: ['primary','dark'],
  data() {
    return {
      hover: false,
    };
  }
}
</script>
<style>
.phone {
  align-self: center;
  text-decoration: inherit;
  margin: auto;
  min-height: 19px;
  min-width: 124px;
  text-align: center;
}

.phoneIconBtn {
    display: flex;
    align-items: flex-start;
    width: 55px;
    background: var(--primary-color);
    border-radius: 25px;
    cursor: pointer;
    padding: 6px 0px;
    color: var(--white);
    text-align: center;
    text-decoration: inherit;
    z-index: 10;
    height: 39px;
    box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.2);
}

.phoneIconBtn:hover {
    padding: 12px 0px;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.05em;
    cursor: pointer;
    height: 39px;
    min-width: 187px;
    color: var(--white);
}

</style>

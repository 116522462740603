var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"taglistSection sectionPadding",attrs:{"id":"how"}},[_c('div',{staticClass:"tag-list"},[_c('div',{staticClass:"left-tag-list"},[_c('div',{staticClass:"tag"},[_vm._m(0),_c('h5',{staticClass:"white"},[_vm._v(_vm._s(_vm.$t("TagList.tag-1")))])]),_c('div',{staticClass:"tag"},[_vm._m(1),_c('h5',{staticClass:"white"},[_vm._v(_vm._s(_vm.$t("TagList.tag-2")))])]),_c('div',{staticClass:"tag"},[_vm._m(2),_c('h5',{staticClass:"white"},[_vm._v(_vm._s(_vm.$t("TagList.tag-3")))])]),_c('div',{staticClass:"tag"},[_vm._m(3),_c('h5',{staticClass:"white"},[_vm._v(_vm._s(_vm.$t("TagList.tag-4")))])])]),_c('div',{staticClass:"right-tag-list"},[_c('div',{staticClass:"tag"},[_vm._m(4),_c('h5',{staticClass:"white"},[_vm._v(_vm._s(_vm.$t("TagList.tag-5")))])]),_c('div',{staticClass:"tag"},[_vm._m(5),_c('h5',{staticClass:"white"},[_vm._v(_vm._s(_vm.$t("TagList.tag-6")))])]),_c('div',{staticClass:"tag"},[_vm._m(6),_c('h5',{staticClass:"white"},[_vm._v(_vm._s(_vm.$t("TagList.tag-7")))])]),_c('div',{staticClass:"tag"},[_vm._m(7),_c('h5',{staticClass:"white"},[_vm._v(_vm._s(_vm.$t("TagList.tag-8")))])])])]),_c('div',{staticClass:"textBox"},[_c('div',{staticClass:"upperTitleText"},[_vm._v(_vm._s(_vm.$t("TagList.upper-title")))]),_c('h2',{staticClass:"primary-dark-color"},[_vm._v(_vm._s(_vm.$t("TagList.title")))]),_c('div',{staticClass:"paragraph secondary-dark-color"},[_vm._v(" "+_vm._s(_vm.$t("TagList.text"))+" ")]),_c('div',{staticClass:"zoneBtn"},[_c('a',{staticClass:"btn btn-light textBtn",attrs:{"href":"tel:+15149626889"}},[_vm._v(" "+_vm._s(_vm.$t("Button.text-1"))+" ")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iconBox"},[_c('img',{staticClass:"svgWhite",attrs:{"src":require("../assets/Check.svg"),"alt":" check icon","width":"100%","height":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iconBox"},[_c('img',{staticClass:"svgWhite",attrs:{"src":require("../assets/Check.svg"),"alt":" check icon","width":"100%","height":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iconBox"},[_c('img',{staticClass:"svgWhite",attrs:{"src":require("../assets/Check.svg"),"alt":" check icon","width":"100%","height":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iconBox"},[_c('img',{staticClass:"svgWhite",attrs:{"src":require("../assets/Check.svg"),"alt":" check icon","width":"100%","height":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iconBox"},[_c('img',{staticClass:"svgWhite",attrs:{"src":require("../assets/Check.svg"),"alt":" check icon","width":"100%","height":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iconBox"},[_c('img',{staticClass:"svgWhite",attrs:{"src":require("../assets/Check.svg"),"alt":" check icon","width":"100%","height":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iconBox"},[_c('img',{staticClass:"svgWhite",attrs:{"src":require("../assets/Check.svg"),"alt":" check icon","width":"100%","height":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iconBox"},[_c('img',{staticClass:"svgWhite",attrs:{"src":require("../assets/Check.svg"),"alt":" check icon","width":"100%","height":"100%"}})])
}]

export { render, staticRenderFns }